body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: static !important; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gskiptranslate,.goog-te-banner-frame,#goog-gt-tt, .goog-te-balloon-frame,div#goog-gt-{
  display: none;
}
.skiptranslate {
  display: none !important;
}
.goog-te-gadget {
  display: block !important;
}

.goog-text-highlight {
  background: none;
  box-shadow: none;
}

.goog-te-combo{
  margin-left:4px !important;
}

#google_translate_element {
  background-color: #f3f3f3;;
}

#google_translate_element select {
  background:#fff;
  margin-left: 20px;
  color:#222;
  border: solid #999 1px;
  border-radius: 5px;
  padding:6px 8px
}
